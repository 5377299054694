.heading_wrapper {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    margin: auto;
}

.job_title {
    margin: auto;
}

@media screen and (min-width: 601px){
    .heading_wrapper{
        gap: 20px;
    }
}
