@media screen and (min-width: 601px) {
    .button_container {
        display: flex;
        flex-direction: column;
    }

    .desktop_contact {
        display: contents;
    }
}

@media screen and (max-width: 600px) {
    .button_container {
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        justify-content: center;
    }
    .desktop_contact {
        display: none;
    }
}
