.game_button_container {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: black;
    border-radius: 70px;
    border: 3px solid rgb(209, 210, 210);
}

.btn_div_red {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.btn_div_yellow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.red {
    height: 75px;
    width: 90px;
    border-radius: 20px;
}

.blue {
    height: 75px;
    width: 90px;
    border-radius: 20px;
    margin-right: 30px;
}

.green {
    height: 75px;
    width: 90px;
    border-radius: 20px;
    opacity: 1;
}

.yellow {
    height: 75px;
    width: 90px;
    border-radius: 20px;
}

.animated {
    box-shadow: 0 0 90px rgb(255, 98, 0);
    transform: scale(0.9);
    opacity: 0.3;
}

.text {
    color: rgb(93, 246, 17)
}

@media screen and (max-width:600px){
    .game_button_container{
        width: 90%;
    }
}

@media screen and (min-width:601px){
    .game_button_container{
        width: fit-content
    }
}

