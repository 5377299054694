.about_me_wrapper {
    height: 100vh;
    display: flex;
    background: black;
}

.about_me_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 15px;
    margin-left: 15px;
    padding-bottom: 10px;
    height: fit-content;
    width: 65%;
    background: rgba(0, 0, 0, 0.7);
    /* background: rgba(255, 255, 255, 0.4); */
}

.about_me_heading {
    font-family: "Red Hat Display", sans-serif;
    font-size: 2vh;
    color: white;
}

.about_me_text {
    font-family: "Montserrat", sans-serif;
    font-size: .8vh;
    /* color: white; */
    /* width: 95%; */
    /* font-weight: 300; */
}

@media screen and (max-width: 600px) {
    .about_me_wrapper {
        display: none;
    }
    .about_me_div{
        margin-top: 0;
        padding-top: 15px;
        width: 95%;
        background: rgba(255, 255, 255, 0.1)
    }
    .about_me_wrapper_mobile {
        height: 100vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        /* object-fit: contain; */
    }
    .about_me_heading {
        color: #fff;
    }
    .about_me_text {
        font-size: 1.1vh;
        width: 80%;
        color: #fff;
        font-weight: 700;
    }
}

@media screen and (min-width: 601px) {
    .about_me_heading {
        font-size: 4vh;
    }
    .about_me_text {
        font-size: 2vh;
        width: 80%;
        font-weight: 300;
        /* text-shadow: 2px 2px 2px white; */
        color: #fff;
    }
    .about_me_wrapper_mobile {
        display: none;
    }
}
