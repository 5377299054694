.name_icon {
    font-size: 3vw;
    overflow: hidden;
    color: white;
    margin: 0;
}

.tech {
    overflow: hidden;
}

@media screen and (min-width: 601px) {
    .name_icon {
        font-size: 2vw;
    }
}

@media screen and (max-width: 600px) {
    .technology_div {
        margin-top: 10px;
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: end;
    }
}