@media screen and (min-width:601px){
  .desktop {
    display: contents;
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width:600px){
  .desktop {
    display: none;
  }
  .mobile {
    display: contents;
  }
}
