.game_wrapper {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-bottom: 30px;
}

.start_home_div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 15px;

}

.start_button_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10rem;
}

.start_home_button {
    font-family: VT323, monospace;
    color: rgb(93, 246, 17);
    border: 3px solid red;
    width: fit-content;
    font-size: 5vh;
    height: fit-content;
    background-color: black;
}

.instructions {
    font-family: VT323, monospace;
    color: rgb(93, 246, 17);
    font-size: 3vh;
    height: fit-content;
    background-color: black;
}

.score {
    display: flex;
    justify-content: center;
    font-family: VT323, monospace;
    color: rgb(93, 246, 17);
    font-size: 7vh;
    height: fit-content;
    background-color: black;
}

.game_button_container {
    border: 3px solid rgb(209, 210, 210);
}

@media screen and (max-width: 600px) {
    .start_home_container {
        height: 25rem;
        width: 90%;
    }
    .start_home_button{
        font-size: 3.5vh;
    }
    .game_button_container {
        width: 90%;
    }
}

@media screen and (min-width: 601px) {
    .start_home_container {
        height: 25rem;
        width: 60%;
    }
    .game_button_container {
        width: fit-content;
    }
}
