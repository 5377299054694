.contact_link {
    color: #fff;
}

@media screen and (min-width: 601px) {
    .contact_container {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
    .icon {
        font-size: 5vw;
        width: fit-content;
        color: #fff;
    }
}

@media screen and (max-width: 600px) {
    .contact_container {
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .icon {
        margin-left: 10px;
        font-size: 9vw;
        color: #fff;
    }
}
