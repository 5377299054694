.home_mobile_wrapper {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
}

.profile_contacts_projects_wrapper {
    display: flex;
    flex-direction: row;
}

.contacts_technologies_div {
    display: flex;
    width: 45%;
}

.projects_profile_div {
    width: 55%;
    overflow: hidden;
    /* background: rgba(0, 0, 0, 0.8);
    border-radius: 10px; */
}

