.projects_wrapper {
    color: #fff;
    margin-top: 20%;
}

.projects_heading_wrapper {
    display: flex;
}

.projects_title {
    width: 50%;
}

.logo {
    height: 150px;
    border-radius: 100%;
}

@media screen and (max-width: 600px) {
    .projects_wrapper {
        margin-top: 10px;
        margin-left: 15px;
        width: 85%;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
    }
    .logo {
        height:3rem;
        border-radius: 100%;
    }
}
